<template>
  <div>
    <div>
      <div>
        <v-textarea
          append-icon="mdi-close"
          @click:append="description = ''"
          outlined
          rows="2"
          auto-grow
          v-model="description"
          label="Description"
        ></v-textarea>
      </div>
    </div>
    <BaseFilepond
      :user="uid"
      @upload="$emit('upload')"
      :description="description"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
    uid() {
      return this.user.uid
    }
  },
  data() {
    return {
      description: ''
    }
  }
}
</script>

<style>
</style>