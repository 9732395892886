<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          Upload File
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <div class="my-2">
            <v-alert type="info" text>
              If you want to upload file to a specific parcel and feature,
              please go to those areas of Peeps.
            </v-alert>
          </div>
          <UploadForm @upload="$emit('upload')" />
        </v-container>
      </v-card>
    </v-dialog>
    <v-btn large class="text-capitalize" color="primary" @click="dialog = true"
      >Upload File</v-btn
    >
  </div>
</template>

<script>
import UploadForm from './UploadForm.vue'
export default {
  components: {
    UploadForm
  },
  data() {
    return {
      dialog: false
    }
  }
}
</script>

<style>
</style>
UploadForm